
import { Vue, Component } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'OperationRecord'
})
@KeepAlive
export default class OperationRecord extends Vue {
  searchForm = {
    projectId: '',
    startDate: '',
    endDate: ''
  }

  url = ''
  dateArr = []

  page = 1
  size = 10
  total = 0
  items = []
  drowerOperatingId = ''

  drawerShow = false

  get projectList() {
    return this.$store.state.projectList
  }

  get userInfo() {
    return JSON.parse(sessionStorage.getItem('userInfo') as any)
  }

  created() {
    this.loadData()
  }

  copyHref() {
    console.log('分享')
    // 创建一个DOM元素 textarea
    const copyInp: any = document.createElement('textarea')
    // 设置高度为0  透明度为0
    copyInp.style.height = 0
    copyInp.style.opacity = 0
    // 添加到页面上
    document.body.appendChild(copyInp)
    // 将需要复制的内容赋值给textarea
    copyInp.innerHTML = this.url
    copyInp.select()
    const docu: any = document
    docu.execCommand('copy')
    // console.log('url', this.url)
    // console.log('参数2', this.userInfo)
    this.$message.success('链接复制成功')
  }

  loadData() {
    this.$axios.get(this.$apis.operationmanage.selectOperatingByPage, {
      ...this.searchForm,
      page: this.page,
      size: this.size
    }).then(res => {
      console.log('res', res)
      this.items = res.list
      this.total = res.total
    })
  }

  onSearch() {
    this.page = 1
    this.loadData()
  }

  onExport() {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.operationmanage.exportOperatingInfoList, this.searchForm).finally(() => {
      loading.close()
    })
  }

  dateChange(date: any) {
    if (date) {
      this.searchForm.startDate = date[0]
      this.searchForm.endDate = date[1]
    } else {
      this.searchForm.startDate = ''
      this.searchForm.endDate = ''
    }
  }

  deleteRow(operatingId: string) {
    this.$confirm('确认删除该记录嘛？', '提示').then(() => {
      this.$axios.post(this.$apis.operationmanage.deleteOperating, {
        operatingId
      }).then(() => {
        this.loadData()
      })
    })
  }

  shareRow(row: any) {
    // this.urlParams = row
    console.log('2323', row)
    this.drowerOperatingId = row.operatingId
    // console.log('')
    this.url = `${location.origin}/h5/daily/?customerCode=${this.userInfo.customerCode}&operatingId=${row.operatingId}&userName=${encodeURIComponent(this.userInfo.name)}`

    this.drawerShow = true
  }

  handleClose() {
    this.drawerShow = false
  }

  // 日报导出
  exportRow(row: any) {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadReport(this.$apis.operationmanage.selectOperatingShareExport, { operatingId: row.operatingId }).finally(() => {
      loading.close()
    })
  }

  onExportDailyRecord() {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadReport(this.$apis.operationmanage.selectOperatingShareExport, { operatingId: this.drowerOperatingId }).finally(() => {
      loading.close()
    })
  }
}
